import React from 'react';
import styled from 'styled-components';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const HomePageStyles = styled.main`
  display: flex;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ContentStyles = styled.article`
  flex-basis: 660px;
  flex-grow: 2;
  border-right: 1px solid #e8e8e8;
  padding-top: 4rem;
  justify-content: flex-start;
  > * {
    margin-left: 4rem;
    margin-right: 3rem;
  }
  .services {
    padding-left: 0.8em;
    li:before {
      content: '... ';
      margin-left: -0.8em;
    }
  }
`;

export default function HomePage() {
  return (
    <>
      <GatsbySeo
        title="Paartherapie und Paarberatung"
        description="Für Paare, die sich in einer Krise befinden oder etwas an Ihrer Partnerschaft verändern möchten, bieten wir Paartherapie bzw. –beratung in unserer Praxis in Ravensburg an."
      />
      <Header />
      <HomePageStyles>
        <ContentStyles>
          <h1>Paartherapie in Ravensburg</h1>
          <p>
            Partnerschaften sind die intimsten und nähesten zwischenmenschlichen
            Beziehungen unseres Lebens. Wenn es in unserer Beziehung nicht gut
            läuft, dann kann sich das negativ auf unser ganzes Leben auswirken.
            Viele Herausforderungen, mit denen Paare konfrontiert sind, können
            selbst bewältigt werden. Wann aber ist es sinnvoll sich an eine
            professionelle Paartherapeutin bzw. einen professionellen
            Paartherapeuten zu wenden?
          </p>
          <p>Eine Paartherapie bzw. Eheberatung kann sinnvoll sein, wenn….</p>
          <ul className="services">
            <li>
              festgefahrene und wiederkehrende Konflikte oder Streitmuster die
              Beziehung belasten.
            </li>
            <li>
              die Kommunikation von Missverständnissen geprägt ist und nicht
              offen über Bedürfnisse geredet wird.
            </li>
            <li>
              ein Gefühl der Entfremdung oder des Auseinanderlebens eingetreten
              ist.
            </li>
            <li>
              aktuelle oder frühere Außenkontakte/-beziehungen die Partnerschaft
              belasten oder gefährden.
            </li>
            <li>
              neue Herausforderungen wie z.B. durch berufliche Veränderungen,
              Elternschaft oder Erkrankung nicht wie gewünscht bewältigt werden
              können.
            </li>
            <li>
              wenn eine/r von beiden oder eines der Kinder psychische
              Schwierigkeiten oder Auffälligkeiten zeigt. Oft besteht dann ein
              Zusammenhang zur Paarebene und zeigt den Veränderungsbedarf wie
              ein Seismograf an.
            </li>
            <li>
              frühere Lebenserfahrungen, wie z.B. Verletzungen, und damit
              verbundene starke negative Gefühle, wie Wut, Angst, Enttäuschung
              oder Eifersucht, die gegenwärtige Beziehung negativ beeinflussen.
            </li>
            <li>
              es unklar ist ob bzw. wie eine Partnerschaft weitergeführt werden
              kann und die Frage nach Trennung oder Zusammenbleiben im Raum
              steht
            </li>
          </ul>
          <p>
            Wir bieten Interessierten aus Ravensburg, dem oberschwäbischen
            Umland, Wangen, Friedrichshafen und der Bodenseeregion gerne
            Unterstützung an.
          </p>
          <p>
            Das Vorgehen und die Ziele werden individuell mit Ihnen erarbeitet
            und abgestimmt. Das ist ein erster wichtiger, bereits klärender
            Schritt. Die Zusammenarbeit orientiert sich immer an Ihrem Bedarf
            und an dem was Sie als hilfreich und sinnvoll erleben. In folgende
            Richtungen kann es dabei gehen….
          </p>
          <ul className="services">
            <li>Verständnis statt Missverständnis</li>
            <li>Nähe statt Entfremdung</li>
            <li>Wertschätzung statt Missgunst</li>
            <li>Klarheit statt Ungewissheit</li>
            <li>liebevolle Begegnungen statt Streit</li>
            <li>Miteinander statt Gegeneinander</li>
            <li>
              offen über Bedürfnisse und Wünsche reden statt an diesen vorbei zu
              leben
            </li>
            <li>
              Verantwortung für sich und die Beziehungsgestaltung übernehmen
              statt Schuldvorwürfe oder Passivität
            </li>
          </ul>
          <p>
            Wenn Sie nach diesen Beispielen den Eindruck haben, dass
            Paartherapie für Sie sinnvoll sein könnte, oder Sie noch Fragen dazu
            haben, würde es uns freuen, wenn Sie Kontakt mit uns aufnehmen.
          </p>
          <p>
            Bei Paartherapie-Anfragen können Sie uns gerne eine Mail schreiben.
            Bitte nennen Sie eine Telefonnummer damit wir Sie zurückrufen
            können, um das weitere Vorgehen in einem Telefonat zu klären.
          </p>
        </ContentStyles>
        <Sidebar />
      </HomePageStyles>
    </>
  );
}
